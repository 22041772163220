import React, { Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import { initCodeList, initLocals } from "./store/server";
import { changeLocale } from "./store/actions/locale";
import { Route, Switch, Redirect } from "react-router-dom";
import * as Routers from "./routers";
import PrivateRoute from "./private-route";
import { getParamObjFromUrl } from "./commons/url-tools";
import ConfigApi from "./server/api/config-api";
import { ThunkDispatch } from "redux-thunk";
import { history } from "./store/reducers/router";
import { ConnectedRouter } from "connected-react-router";
import "./css/main.scss";
import Loading from "components/waitting/index";
const Home = lazy(() => import("./views/home/home"));
const OpenAccount = lazy(() => import("./views/open-account"));
const SetPwd = lazy(() => import("./views/home/set-pwd"));
const ForgetPwd = lazy(() => import("./views/home/find-pwd"));
const Status = lazy(() => import("./views/status/status"));
const Agreement = lazy(() => import("./views/home/agreement"));
interface MyProps {
  currLocale: string;
  codeList: { [key: number]: string };
  changeLocale: (param: string) => any;
  initLocal: () => any;
  initCodeList: (param: string) => any;
}

const APP_CONFIG = (window as any).APP_CONFIG;
class App extends Component<MyProps, any> {
  state = {
    isRender: false,
  };
  componentDidMount() {
    const local = sessionStorage.getItem("currLocale");
    if (local) {
      this.props.initCodeList(local);
      this.initData();
    } else {
      Promise.all([this.props.initLocal()]).then(() => {
        this.props.initCodeList(this.props.currLocale);
        this.initData();
      });
    }
  }
  initData = async () => {
    const { orgID, orgName } = getParamObjFromUrl() as any;
    if (orgID) {
      APP_CONFIG.orgID = orgID;
      APP_CONFIG.orgName = orgName;
    } else {
      try {
        const [config] = await ConfigApi.queryDefaultOrg();
        const id = APP_CONFIG.orgID;
        APP_CONFIG.orgID = config.orgId || id;
        APP_CONFIG.orgName = config.orgName;
      } catch (err) {
        console.log("queryDefaultOrg", err);
      }
    }
    this.setState({
      isRender: true,
    });
  };

  changeLocales = async (value: string) => {
    this.props.changeLocale(value);
    this.props.initCodeList(value);
  };

  render() {
    const locale = sessionStorage.getItem("currLocale") || "zh-tc";
    const { codeList, currLocale } = this.props;
    if (!this.state.isRender) {
      return null;
    }
    return (
      <ConnectedRouter history={history}>
        <IntlProvider
          locale='zh-tc'
          messages={codeList}
          defaultLocale='zh-tc'
          onError={() => {}}>
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route
                path={Routers.Login}
                exact
                component={(props: any) => (
                  <Home
                    {...props}
                    currLocale={currLocale}
                    changeLocale={this.changeLocales}
                  />
                )}
              />
              <PrivateRoute path={Routers.SetPwd} component={SetPwd} />
              <Route path={Routers.ForgetPwd} component={ForgetPwd} />
              <PrivateRoute
                path={Routers.OpenAccount}
                component={OpenAccount}
              />
              <PrivateRoute path={Routers.Status} component={Status} />
              <Route path={Routers.Agreement} component={Agreement} />
              <Redirect to={Routers.Login} />
            </Switch>
          </Suspense>
        </IntlProvider>
      </ConnectedRouter>
    );
  }
}

const mapStateToProps = (state: {
  locale: {
    localeInfo: { currLocale: string };
    codeList: { [key: number]: string };
  };
}) => {
  return {
    currLocale: state.locale.localeInfo.currLocale,
    codeList: state.locale.codeList,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  changeLocale: (param: string) => dispatch(changeLocale(param)),
  initLocal: () => dispatch(initLocals),
  initCodeList: (param: string) => dispatch(initCodeList(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
