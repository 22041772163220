/**
 * Created by admin on 2017/6/12.
 */
import HTTP from '../http';
import { wrapLanData, wrapOrgData, wrapLanOrgData } from '../tool';

const APP_CONFIG = window.APP_CONFIG
const getConfigUrl = url => `${APP_CONFIG.URL}config/${url}`;
const getConfigNoLanUrl = url => `${APP_CONFIG.URL}config/noLan/${url}`;

export default class ConfigApi {
  /**
   * 查询平台机构支持的语言列表（不存在时使用运营商的配置）
   * @param p 后台接收参数
   * @param o fetch参数
   * @returns {*}
   */
  static queryLanList(obj) {
    return HTTP.postJson(getConfigNoLanUrl('queryLanList'), wrapOrgData(obj));
  }


  /**
   * 手动刷新缓存
   * @param p 后台接收参数
   * @param o fetch参数
   * @returns {*}
   */
  static refreshLanCode(obj) {
    return HTTP.postJson(getConfigNoLanUrl('refreshLanCode'), obj);
  }


  /**
   * 查询系统配置
   */
  static querySysConfig(obj) {
    return HTTP.postJson(getConfigUrl('querySysConfig'), wrapOrgData(obj));
  }

  /**
   * 查询系统配置
   */
  static queryAgreement(obj) {
    return HTTP.postJson(getConfigUrl('queryAgreement'), wrapLanOrgData(obj));
  }

  /**
   * 查询国家编号（可以使用查询模块语言编码接口）
   */
  static queryCountryList(obj) {
    return HTTP.postJson(getConfigUrl('queryCountryList'), wrapLanData(obj));
  }


  /**
   * 查询模块语言编码
   */
  static queryModuleLanCodeList(obj) {
    return HTTP.postJson(getConfigUrl('queryModuleLanCodeList'), wrapLanData(obj));
  }

  /**
   * 机构动态模块语言编码查询
   */
  static queryDynamicCodeKvInOrg(obj) {
    return HTTP.postJson(getConfigUrl('queryDynamicCodeKvInOrg'), wrapLanOrgData(obj));
  }
  /**
   * 查询默认的注册机构
   */
  static queryDefaultOrg(obj) {
    return HTTP.postJson(getConfigUrl('queryDefaultOrg'), wrapLanData(obj));
  }
  /**
   * 查询国家
   */
  static queryArea(obj) {
    return HTTP.postJson(getConfigUrl('queryArea'), wrapLanData(obj));
  }

}
