import axios from 'axios';
import qs from 'qs';
import { getParamObjFromUrl } from '../commons/url-tools';
import Tips from '../components/tips/tips';

// import * as Routers from '../routers';
import { Cookie } from '../commons/tools';
import * as Routers from '../routers';

const http = axios.create({});
http.defaults.headers = {
  'Content-Type': 'application/x-www-form-urlencoded',
};

http.defaults.transformRequest = [
  (p, headers) => {
    const param = {
      ...p,
      // sessionCode: Cookie.getCookie('sessionCode'),
    };
    switch (headers['Content-Type']) {
      case 'application/x-www-form-urlencoded':
        return qs.stringify(param);
      case 'application/json':
        return JSON.stringify(param);
      default:
        return param;
    }
  },
];
http.postJson = (url, data, config = { headers: {} }) => http.post(url, data, {
  ...config,
  headers: {
    'Content-Type': 'application/json',
    ...config.headers,
  },
});

http.interceptors.response.use(
  (res) => new Promise((resolve, reject) =>{
    const rs = res.data;
    if (Number(rs.code) !== 0){
        const param = getParamObjFromUrl();
      if (rs.code === window.APP_CONFIG.SESSION_CODE_EXPIRE) {
          // TODO 登录页跳转
          Cookie.deleteCookie('sessionCode');
          localStorage.removeItem('cache_UserInfo');
          if (sessionStorage.getItem('sessionCode')) {
            sessionStorage.removeItem('sessionCode');

          }
          window.location.hash = `#${Routers.Login}`;
          return reject([rs.message, rs]);
        } else{
          return reject([rs.message, rs]);
        }
      }
    return resolve([rs.result || null, rs]);
  }),
  (err) => new Promise((resolve, reject) =>{
    if (!sessionStorage.getItem('sessionCode')) {
      // window.location.href = Routers.Login;
    }
    reject(`未知错误${err}`)
  }),
);

export default http;
