/* eslint-disable react/require-default-props */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Routers from './routers';
import { Cookie } from './commons/tools';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      (Cookie.getCookie('sessionCode')) ? (
        <Component {...props} />
      ) : (
          <Redirect to={{ pathname: Routers.Login, state: { type: 'replace', href: props.location } }} />  // eslint-disable-line
        )
    )}
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.any,
};

export default PrivateRoute;
