import { initLocal, changeCodeList, initLanList } from "./actions/locale";
// import HomeApi from '../server/api/h';
import HomeApi from "../server/api/config-api";

export async function initLocals(dispatch) {
  try {
    const res = await HomeApi.queryLanList();
    const currLocale = res[0].find((item) => Number(item.def) === 1);
    dispatch(initLocal({ currLocale: currLocale.key }));
    sessionStorage.setItem("lanList", JSON.stringify(res[0]));
    dispatch(initLanList(res[0]));
  } catch (err) {
    console.log('queryLanList', err);
  }
}

export function initCodeList(lan = sessionStorage.getItem("currLocale")) {
  return async (dispatch) => {
    try {
      const localCode = await HomeApi.queryModuleLanCodeList({
        lan: lan,
        where: [{ module: "H5" }, { module: "common" }],
      });

      let arr = {};
      let contryCode = [];
      if (localCode[0].length) {
        for (let i = 0; i < localCode[0].length; i++) {
          const data = localCode[0][i];
          arr[data[0]] = data[1];
          for (let j = 1; j < data.length; j++) {
            arr[data[j][0]] = data[j][1];
            if (data[j][5] === "nationality") {
              let item = {};
              item.code = data[j][0];
              item.value = data[j][1];
              contryCode = [...contryCode, item];
            }
          }
        }
      }
      sessionStorage.setItem("contryCode", JSON.stringify(contryCode));
      return dispatch(changeCodeList(arr));
    } catch (err) {
      console.log('initCodeList', err);
    }
  };
}
