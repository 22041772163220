/**
 * Created by Amg on 2017/2/14.
 */

// export { default as anyQs } from 'any-qs';

// 获取地址栏所有参数转换为对象
export function getParamObjFromUrl(url) {
  let str = url || location.href; // 取得整个地址栏
  const end = str.indexOf('#');
  str = str.substring(0, end);
  const num = str.indexOf('?');
  str = str.substr(num + 1); // 取得所有参数   stringvar.substr(start [, length ]
  const arr = str.split('&'); // 各个参数放到数组里
  const paramData = {};
  for (let i = 0; i < arr.length; i += 1) {
    const n = arr[i].indexOf('=');
    if (n > 0) {
      const name = arr[i].substring(0, n);
      const value = decodeURIComponent(arr[i].substr(n + 1));
      paramData[name] = value;
    }
  }
  return paramData;
}

// 判断是否是登录状态
export function isLogin(loginParamField) {
  if (loginParamField) {
    return loginParamField !== '0';
  }
  return false;
}

