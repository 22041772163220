import { combineReducers } from "redux";
import * as types from "../actions/locale";
import { LocaleActions } from "../actions/locale";

export interface LocaleState {
  localeInfo: {currLocale: string}
  codeList: {[key: number]: string}
  lanList: any[]
}

const initialState = {
  currLocale:
    sessionStorage.getItem("currLocale") ||
    (window as any).APP_CONFIG.defaultLan,
};

function localeInfo(state = initialState, action: LocaleActions) {
  switch (action.type) {
    case types.SWITCH_LOCALEN:
      sessionStorage.setItem("currLocale", action.data);
      return {
        currLocale: action.data,
      };
    case types.INIT_LOCAL:
      sessionStorage.setItem("currLocale", action.data.currLocale);
      return action.data;
    default:
      return state;
  }
}
function codeList(
  state = JSON.parse(sessionStorage.getItem("lanCodeList") || "{}") || {},
  action: LocaleActions
) {
  switch (action.type) {
    case types.CHANGE_CODE_LIST:
      sessionStorage.setItem("lanCodeList", JSON.stringify(action.data));
      return action.data;
    default:
      return state;
  }
}
function lanList(state = [], action: LocaleActions) {
  switch (action.type) {
    case types.INIT_LAN_LIST:
      return action.data;
    default:
      return state;
  }
}

export default combineReducers({ localeInfo, codeList, lanList });
