/**
 * Created by dell on 2017/6/10.
 */
import cookie from 'cookie';
import ReactDOM from 'react-dom';
export const isWX = (/micromessenger/).test(window.navigator.userAgent.toLowerCase());
export const isAPP = (/dzapp/).test(window.navigator.userAgent.toLowerCase());
export const isWB = (/weibo/).test(window.navigator.userAgent.toLowerCase());
export const isQQ = (/\sQQ/i).test(window.navigator.userAgent.toLowerCase());
export const isIOS = (/(iphone|ipad|ipod|ios)/).test(window.navigator.userAgent.toLowerCase());
export const isAndroid = (/android/).test(window.navigator.userAgent.toLowerCase());
export const isMobile = (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i)
.test(window.navigator.userAgent.toLowerCase());
// 增加时间秒数
export function dateAddSeconds(sec) {
  return new Date((new Date()).getTime() + (sec * 1000));
}

// cookie处理
export class Cookie {
  static setCookie(name, val, option) {
    const v = (typeof val === 'string') ? val : JSON.stringify(val);
    document.cookie = cookie.serialize(name, v, option);
  }

  static setCookieExpireInSecond(name, val, second, option) {
    Cookie.setCookie(name, val, { expires: dateAddSeconds(second), ...option });
  }

  static getCookie(cName) {
    const p = cookie.parse(document.cookie);
    if (cName in p) {
      return p[cName];
    }
    return null;
  }

  static getJSONCookie(cName) {
    return JSON.parse(Cookie.getCookie(cName));
  }

  static deleteCookie(cName) {
    Cookie.setCookie(cName, '', { maxAge: -1 });
  }
}

export function insertComponent(component) {
  const el = document.createElement('div');
  document.body.appendChild(el);
  ReactDOM.render(component, el);
  // 对象拓展
  el.close = () => {
    el.style.display = 'none';
  };
  el.show = () => {
    el.style.display = 'block';
  };
  //
  return el;
}

export function removeComponentByRef(ref) {
  const p = ref.parentNode;
  ReactDOM.unmountComponentAtNode(p);
  p.parentNode.removeChild(p);
}
//将base64图片转换为Blob对象

export function convertBase64UrlToBlob(urlData, filetype){
  //去掉url的头，并转换为byte
  var bytes = window.atob(urlData.split(',')[1]);
  //处理异常,将ascii码小于0的转换为大于0
  var ab = new ArrayBuffer(bytes.length);
  var ia = new Int8Array(ab);
  var i;
  for (i = 0; i < bytes.length; i++) {
    ia[i] = bytes.charCodeAt(i);
  }
  return new Blob([ab], {type : filetype});
}
export function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  // return new Blob([u8arr], {
  //     type: mime
  // });
  return u8arr
}
export function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}
// 时间格式化
export function dateFormat(d, format = 'yyyy-MM-dd') {
  if (!d) return '';
  let date = d;
  switch (typeof date) {
    case 'string':
      date = new Date(date.replace(/-/g, '/'));
      break;
    case 'number':
    default:
      date = new Date(date);
  }
  if (!(date instanceof Date)) return '';

  const dict = {
    yyyy: date.getFullYear(),
    M: date.getMonth() + 1,
    d: date.getDate(),
    H: date.getHours(),
    m: date.getMinutes(),
    s: date.getSeconds(),
    MM: (`${date.getMonth() + 101}`).substr(1),
    dd: (`${date.getDate() + 100}`).substr(1),
    HH: (`${date.getHours() + 100}`).substr(1),
    mm: (`${date.getMinutes() + 100}`).substr(1),
    ss: (`${date.getSeconds() + 100}`).substr(1),
  };
  try {
    return format.replace(/(yyyy|MM?|dd?|HH?|ss?|mm?)/g, f => dict[f]);
  } catch (e) {
    return '';
  }
}
