/**
 * Created by admin on 2016/11/2.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './tips.module.scss';

import { dataS2t, insertComponent, removeComponentByRef } from '../../commons/tools';

class TipsWrap extends Component {
  static propTypes = {
    content: PropTypes.string.isRequired,
    parentRef: PropTypes.any,
    time: PropTypes.number,
    onDisappear: PropTypes.func,
  };

  static defaultProps = {
    parentRef: undefined,
    time: 0,
    onDisappear: () => {},
  };

  componentDidMount() {
    this.layout();
    this.timer = setTimeout(() => {
      this.close();
    }, this.props.time || 2000);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    this.timer = null;
  }

  layout() {
    let rect;
    if (this.props.parentRef === undefined) {
      const e = document.documentElement;
      rect = { left: 0, top: 0, width: e.clientWidth, height: e.clientHeight };
    } else {
      rect = this.props.parentRef.getBoundingClientRect();
    }
    const r = this.tips.getBoundingClientRect();
    const left = rect.left + ((rect.width - r.width) / 2);
    const top = rect.top + ((rect.height - r.height) / 2);
    const style = `top: ${top}px; left:${left}px;`;
    this.tips.setAttribute('style', style);
  }

  close() {
    if (this.props.onDisappear) this.props.onDisappear();
    removeComponentByRef(this.tips);
  }

  render() {
    // const content = typeof this.props.content === 'string' ? dataS2t(this.props.content) : this.props.content;
    return (
      <div
        ref={(ref) => { this.tips = ref; }}
        className={styles.tips}
        // dangerouslySetInnerHTML={{ __html: this.props.content }}
      >
        {this.props.content}
      </div>
    );
  }
}

export default class Tips extends TipsWrap {
  static show(param, time, ref = undefined) {
    if (typeof param === 'object' && param.length !== 0) {
      insertComponent(<TipsWrap {...param} parentRef={ref} />);
    } else if (typeof param === 'string') {
      insertComponent(<TipsWrap content={param} parentRef={ref} time={time} />);
    }
  }
}

