import { RouterState } from 'connected-react-router';
import { combineReducers } from 'redux';
import locale, { LocaleState } from './locale';
import { routerReducer } from './router'

export interface RootState {
  router: RouterState
  locale: LocaleState
}

export default combineReducers({
  router: routerReducer,
  locale,
});