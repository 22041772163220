export const SWITCH_LOCALEN = 'HOME_MESSAGE';
export function changeLocale(currLocale: string) {
  return {
    type: SWITCH_LOCALEN,
    data: currLocale,
  };
}
export const INIT_LOCAL = 'INIT_LOCAL';
export function initLocal(currLocale: string) {
  return {
    type: INIT_LOCAL,
    data: currLocale,
  }
}
export const CHANGE_CODE_LIST = 'CHANGE_CODE_LIST';
export function changeCodeList(data: any) {
  return {
    type: CHANGE_CODE_LIST,
    data,
  }
}

export const INIT_LAN_LIST = 'INIT_LAN_LIST';
export function initLanList(data: any) {
  return {
    type: INIT_LAN_LIST,
    data,
  };
}


export const mergeZhType = 'LOCALE_MERGE_ZHCH';
export function mergeZhCH(zhch: any) {
  return {
    type: mergeZhType,
    data: zhch,
  };
}

export const mergeEnType = 'LOCALE_MERGE_ENUS';
export function mergeEnUs(enus: any) {
  return {
    type: mergeEnType,
    data: enus,
  };
}

export type LocaleActions =
  | ReturnType<typeof changeLocale>
  | ReturnType<typeof initLocal>
  | ReturnType<typeof changeCodeList>
  | ReturnType<typeof initLanList>
  | ReturnType<typeof mergeZhCH>
  | ReturnType<typeof mergeEnUs>