import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "store/store";

let theme = "white";
if (localStorage.getItem("OPEN-ACCOUNT-THEME")) {
  theme = localStorage.getItem("OPEN-ACCOUNT-THEME") || "";
} else {
  localStorage.setItem("OPEN-ACCOUNT-THEME", theme);
}
document.body.setAttribute(
  "data-theme",
  "theme-" + localStorage.getItem("OPEN-ACCOUNT-THEME")
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
