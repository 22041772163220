/**
 * Created by dell on 2020/9/28.
 */
// 首页
export const Login = '/login';
// 设置密码
export const SetPwd = '/set-pwd';
// 忘记密码
export const ForgetPwd = '/forget-pwd';
// 开户
export const OpenAccount = '/open-account';
// 开户前验证
export const Status = '/status';
// 协议
export const Agreement = '/agreement'
